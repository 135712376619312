import {
  Experiment,
  ExperimentClient,
  type ExperimentUser,
  type Variant,
} from '@amplitude/experiment-js-client'
import { isClient, isServer } from '../utils/runtimeUtils'
import { experimentManager } from 'services/ExperimentManager/ExperimentManager'
import { useMemo } from 'react'
import { LANGUAGE_LOCALES, type LANGUAGES } from '../locale/Translation.consts'
import { cc } from '../locale/custom/countries'
import { type PricingItem } from '../components/Pricing/types/pricingItem.types'
import { MATERIAL_TYPES, TILE_SIZES } from '@mixtiles/web-backend-shared'

export function getExperimentsUser(
  userId: string,
  language: string,
  country: string
): ExperimentUser {
  return {
    user_id: userId,
    language: LANGUAGE_LOCALES[language as LANGUAGES],
    country: cc.en[country as keyof typeof cc.en],
  }
}

export function useInitExperimentClient({
  features,
  keys,
  userId,
  language,
}: {
  features: Record<string, Variant>
  keys: Record<string, any>
  userId: string
  language: string
}) {
  const key = keys.amplitudeDeploymentKey
  let client: ExperimentClient = useMemo(() => {
    const userProvider = {
      getUser: () => getExperimentsUser(userId, language, keys.ipCountry),
    }
    if (isServer()) {
      return new ExperimentClient(key, {
        fetchTimeoutMillis: 1000,
        pollOnStart: false,
        initialVariants: features,
        userProvider,
      })
    }

    const client = Experiment.initializeWithAmplitudeAnalytics(key, {
      fetchTimeoutMillis: 1000,
      pollOnStart: false,
      initialVariants: features,
      userProvider,
    })
    // Calling start so the experiments will be saved in the local storage
    client.start(userProvider.getUser())
    return client
  }, [])

  if (isClient()) {
    experimentManager.updateClient(client)
  }

  return client
}

export function checkPriceConsistency(
  items: Record<string, PricingItem>
): boolean {
  // the idea here is to check if the price difference between the base price and the wide frame price is consistent across all sizes
  // if that's the case, we want to show the wide as color in the UI rather than as material
  let priceDifference: number | null = null

  for (const size in TILE_SIZES) {
    const wideFrameTile = `${size}-${MATERIAL_TYPES.WIDE_FRAME}`

    if (items[size] && items[wideFrameTile]) {
      const basePrice = items[size].price
      const wideFramePrice = items[wideFrameTile].price
      const currentDifference = wideFramePrice - basePrice

      if (priceDifference === null) {
        // Set the first difference
        priceDifference = currentDifference
      } else if (priceDifference !== currentDifference) {
        // If any difference doesn't match, return false
        return false
      }
    }
  }

  // If all differences matched, return true
  return true
}
